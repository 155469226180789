import React from "react";
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Contentchin from "../sections/poly/polycontent.js";
import PoContent from "../sections/poly/pocontent.js";
import ChinFaq from "../sections/poly/polyfaq.js";
import Head from "../components/head"
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/HeroComponent";
import imgVideo from "../assets/image/jpeg/poly.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const Polynucleotides = () => {
  return (
    <>
    <Head title="Polynucleotides Treatment in London" image="/images/dermamina-home.jpg"  description="Polynucleotide treatment rejuvenates and repairs skin, offering anti-ageing benefits and healing properties. Ideal for acne, scars, and overall skin health, this innovative therapy revitalizes and enhances your complexion." keywords="polynucleotide treatment, polynucleotide therapy, polynucleotide skin treatment, benefits of polynucleotide treatment, polynucleotide injection, polynucleotide skincare, polynucleotide anti-ageing, polynucleotide rejuvenation, polynucleotide healing, polynucleotide for acne"/>
<PageWrapper headerDark>


<HeroComponent
    itext='<span style="color: #1a1a1a;">Polynucleotide </span> <span style="color: #00aec7;">Treatment</span>'
    secondText="Revitalize with Polynucleotide Therapy"
    videoUrl="https://www.youtube.com/watch?v=J6pCqG_1EvI" // Specify your video URL here
    videoAlt="Polynucleotides"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="3 Sessions"
  bestalt="best Polynucleotides Aessions"
  resultstext="Long Lasting"
  resultsalt="Polynucleotides results"
  costdata="£350"
  costalt="Polynucleotides Cost"
  timedata="20 Minutes"
  timealt="Polynucleotides Duration Time"
  workdata="Immediately"
  workalt="Polynucleotides downtime work"
  paindata="Mild"
  painalt="Polynucleotides Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
  <Contentchin />
 
<Award />
<Testimonial />

<ChinFaq />     
<PoContent />   
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Polynucleotides;
